import { useMedia } from '.';

//Hardcoded height of JournalCard
const cardHeight = {
  small: 285,
  large: 357,
};

const cardWidth = 288;

const defaultFactors = {
  image: {
    large: 0.55,
    small: 0.7,
  },
  body: {
    large: 0.45,
    small: 0.45,
  },
};

function useCardSize(factors = defaultFactors) {
  const isLarge = useMedia(useMedia.LARGE);

  const imgHeight = isLarge
    ? cardHeight.large * factors.image.large
    : cardHeight.small * factors.image.small;

  const bodyHeight = isLarge
    ? cardHeight.large * factors.body.large
    : cardHeight.small * factors.body.small;

  const currentHeight = isLarge ? cardHeight.large : cardHeight.small;

  return { imgHeight, bodyHeight, width: cardWidth, height: currentHeight };
}

export default useCardSize;
