import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { includes } from 'ramda';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import {
  InteractiveLightBox,
  InteractiveLightBoxVideo,
} from 'components/lightbox';
import { Image } from 'components/media';
import { LightBoxDocumentAsset } from 'journals/blocks/output/HtmlBlock/LibraryAsset/DocumentAsset';
import { InteractiveAsset } from 'journals/blocks/output/HtmlBlock/LibraryInteractive';

import { getAssetUrl } from 'utils';
import classNames from 'classnames';
import { getAttachmentFileNameWithoutExtension } from 'my-phr/components/PHRCard/utils';

import { LightboxPodcast } from 'components/media/Podcast/layouts';
import { Controls as ZoomControls, zoomSettings } from './zoom';

const DEFAULT_ASSET_TYPES = ['asset', 'object'];

const getInitialScale = (element) => {
  const windowWidth = window?.innerWidth;
  const elementWidth = element?.target?.naturalWidth;
  if (elementWidth < windowWidth / 2) {
    return 1;
  }
  return 0.7;
};

const LightBoxImage = ({ style, ...props }) => {
  const [loaded, setLoaded] = useState(false);
  return (
    <TransformWrapper {...zoomSettings}>
      {({ centerView, ...utils }) => (
        <>
          <ZoomControls {...utils} />
          <TransformComponent
            wrapperStyle={{
              maxHeight: `100%`,
              maxWidth: '100%',
              width: '100%',
              height: '100%',
            }}
          >
            <Image
              {...props}
              className={classNames('bg-white', {
                visible: loaded,
                invisible: !loaded,
              })}
              onLoad={(element) => {
                setLoaded(true);
                centerView(getInitialScale(element), 0);
              }}
            />
          </TransformComponent>
        </>
      )}
    </TransformWrapper>
  );
};

const lightBoxMapping = {
  document: LightBoxDocumentAsset,
  image: LightBoxImage,
  video: InteractiveLightBoxVideo,
  audio: LightboxPodcast,
  interactive: (props) => (
    <div className="grid max-h-full w-full content-center bg-white p-4 text-black md:w-8/12">
      <InteractiveAsset {...props} />
    </div>
  ),
};
const mapProps = ({ asset, slideProps }) => {
  const assetUsed = asset?.featuredAsset ?? asset;
  const assetType = asset.subtype ?? asset?.type;

  switch (assetType) {
    case 'video':
      return {
        config: assetUsed,
        isActive: slideProps.isActive,
      };
    case 'image':
      return {
        src: getAssetUrl(assetUsed.url),
        alt: assetUsed.alt || assetUsed.title,
      };
    case 'audio':
      return {
        config: asset,
      };
    default:
      return {
        config: assetUsed,
      };
  }
};

export default function AssetsLightBox({
  assets = [],
  lightBoxState,
  onClose = () => {},
  carouselProps,
  additionalActions,
  modifyTitle,
  origin,
  mapPropsFn = mapProps,
}) {
  const [state, setState] = useState({
    toggler: false,
    index: 0,
  });

  useEffect(() => {
    setState(lightBoxState);
  }, [lightBoxState]);

  const lightboxItems = assets.map((asset) => {
    const assetTypeToRender = includes(asset?.type, DEFAULT_ASSET_TYPES)
      ? asset.subtype
      : asset.type;
    const assetUsed = asset.featuredAsset ?? asset;

    const nameWithoutExtension = getAttachmentFileNameWithoutExtension(
      assetUsed?.name
    );

    const displayName = modifyTitle
      ? `${origin} - ${nameWithoutExtension}`
      : assetUsed?.name;

    return (slideProps) => {
      const AssetComponent = lightBoxMapping?.[assetTypeToRender];
      const props = mapPropsFn({ asset, slideProps });
      const hasExpandableCaption =
        Boolean(assetUsed?.description?.trim?.().length) ||
        Boolean(assetUsed?.credit?.name?.trim?.().length);
      return (
        <div className="h-full w-screen" key={asset?.id}>
          <InteractiveLightBox.Element
            hasExpandableCaption={hasExpandableCaption}
          >
            <AssetComponent {...props} />
          </InteractiveLightBox.Element>
          <InteractiveLightBox.Caption
            {...{ ...assetUsed, name: displayName }}
          />
        </div>
      );
    };
  });

  function onCloseHandle() {
    setState((value) => ({ ...value, toggler: false }));
    onClose();
  }

  return (
    <InteractiveLightBox
      openAtIndex={state?.index}
      isOpen={state?.toggler}
      slides={lightboxItems}
      onClose={onCloseHandle}
      carouselProps={carouselProps}
      additionalActions={additionalActions}
    />
  );
}

function useAssetsLightBoxState() {
  const [state, setState] = useState({
    toggler: false,
    index: 0,
  });

  function openLightBox(index = 0) {
    setState({
      toggler: true,
      index,
    });
  }

  return {
    state,
    openLightBox,
  };
}

AssetsLightBox.useAssetsLightBoxState = useAssetsLightBoxState;

AssetsLightBox.propTypes = {
  lightBoxState: PropTypes.shape({
    toggler: PropTypes.bool,
    index: PropTypes.number,
  }),
};
