export type Theme = {
  playButton: string;
  seekButtons: string;
  volumeButton: string;
  progress: string;
  followLabel: string;
  actionButtons?: string;
  miniPlayerExpandButton?: string;
};

const darkButtonBaseClassNames =
  'bg-transparent text-white hover:text-smd-gray-lighter';

const lightButtonBaseClassNames =
  'bg-white text-smd-accent hover:text-smd-accent-dark';

export const darkTheme: Theme = {
  playButton:
    'bg-white text-smd-gray-darker p-2 shadow-md hover:bg-smd-gray-lighter shrink-0',
  seekButtons: darkButtonBaseClassNames,
  volumeButton: darkButtonBaseClassNames,
  progress: 'text-smd-xs text-white',
  followLabel: 'text-white',
};

export const lightTheme: Theme = {
  playButton:
    'bg-smd-accent text-white p-2 shadow-md hover:bg-smd-accent-dark shrink-0',
  seekButtons: lightButtonBaseClassNames,
  volumeButton: lightButtonBaseClassNames,
  progress: 'text-smd-xs text-smd-gray',
  followLabel: 'text-smd-gray-darker',
  actionButtons:
    'bg-white shadow-md text-smd-accent border p-1.5 hover:bg-smd-gray-lightest',
  miniPlayerExpandButton:
    'shadow-md bg-white text-smd-accent hover:bg-smd-gray-lightest shrink-0 p-1',
};
