import { useTranslation } from 'react-i18next';

import { cn } from 'lib/utils';
import { Button } from 'components/forms';

type GuideStepFooterProps = {
  currentStep: number;
  guideLength: number;
  isLastStep: boolean;
  className?: string;
  completeGuide: () => void;
  goToNextStep: () => void;
};

export function GuideStepFooter(props: GuideStepFooterProps) {
  const { t } = useTranslation();

  function onButtonClick() {
    if (props.isLastStep) {
      props.completeGuide();
    } else {
      props.goToNextStep();
    }
  }

  const hasOneStepOnly = props.guideLength === 1;

  return (
    <div
      className={cn(
        'flex items-center',
        hasOneStepOnly ? 'justify-end' : 'justify-between',
        props.className
      )}
    >
      {!hasOneStepOnly && (
        <p className="text-smd-xs font-semibold leading-[1.0625rem]">
          Step {props.currentStep} of {props.guideLength}
        </p>
      )}
      <Button.Primary onClick={onButtonClick} size="sm">
        {props.isLastStep ? t('common.done') : t('common.next')}
      </Button.Primary>
    </div>
  );
}
