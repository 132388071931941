import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { Skeleton } from 'components';
import { PictureResource } from 'components/media';

import { useArticleInfo, useMedia } from 'hooks';
import { extractWebpThumbnail, getFocalPosition } from 'utils';
import { Podcast } from 'components/icons';

export function ArticleCardSkeleton() {
  return (
    <div className="flex w-full shrink-0 flex-col py-4 shadow-md sm:h-40 sm:flex-row">
      <div className="flex flex-grow flex-col overflow-hidden px-smd-md py-smd-sm pb-smd-base sm:py-smd-lg sm:pl-smd-xl sm:pr-smd-xxl">
        <Skeleton.Text className="mb-smd-xxs w-1/3 text-smd-xs" />
        <Skeleton.Text className="mb-smd-xs w-1/2 text-smd-h4" />
        <Skeleton.Text lines="2" trimLastLine className="text-smd-sm" />
      </div>
      <Skeleton className="h-24 w-full shrink-0 sm:h-auto sm:w-72" />
    </div>
  );
}

function PodcastLabel() {
  const { t } = useTranslation();
  return (
    <div className="transition-padding group flex items-center justify-center space-x-1 rounded-full bg-smd-accent-light p-2 text-smd-accent">
      <Podcast className="h-4 w-4 shrink-0" />
      <span className="transition-max-width inline-flex max-w-0 overflow-hidden whitespace-nowrap text-smd-sm font-bold group-hover:max-w-32">
        {t('podcasts.podcast-included')}
      </span>
    </div>
  );
}

function ArticleCard({ article, titleClassName, descriptionClassName }) {
  const { t } = useTranslation();
  const isSmall = useMedia(useMedia.SMALL);

  const {
    featuredAsset,
    title,
    parents,
    plainText,
    highlights,
    hasPodcast,
    thumbnail,
    subtype,
  } = article;

  const { focalPoint, alt, name: imgName, images } = featuredAsset ?? {};
  const { position, isValid } = getFocalPosition(focalPoint);
  const webpThumbnail = extractWebpThumbnail(images);

  const cardImage = webpThumbnail?.path || thumbnail;

  const descriptionElement = (
    <p
      className={classnames(
        descriptionClassName,
        'mt-smd-xs text-smd-sm sm:mt-smd-base',
        cardImage ? 'line-clamp-5 sm:line-clamp-3' : 'line-clamp-3'
      )}
      data-testid="description"
      dangerouslySetInnerHTML={{
        __html: highlights?.description ?? highlights?.plainText ?? plainText,
      }}
    />
  );

  const { isHealthTopic, title: articleTitle, Icon } = useArticleInfo(article);

  const isArticle = subtype === 'article';

  return (
    <article className="flex w-full flex-col bg-white text-smd-main antialiased duration-100 hover:scale-1025 sm:min-h-40 sm:flex-row">
      <div className="flex grow flex-col space-y-1 overflow-hidden px-smd-md pt-smd-sm sm:py-smd-sm sm:pr-smd-xxl">
        <h2
          className={classnames(
            'line-clamp-2 text-smd-h4 font-bold text-smd-accent-bright',
            titleClassName
          )}
        >
          {title}
        </h2>
        <div className="flex items-center space-x-2 text-smd-xs">
          {Icon ? (
            <div className="flex h-8 w-8 shrink-0 items-center justify-center rounded-full bg-smd-gray-lightest">
              <Icon className="h-4 w-4 text-smd-accent" />
            </div>
          ) : (
            <></>
          )}
          <span className="line-clamp-1 text-smd-gray-dark">
            {isHealthTopic
              ? `${articleTitle} `
              : isArticle
              ? `${parents?.[0]?.title} `
              : ''}
            {t(
              `library.search.type.${
                isArticle ? parents?.[0]?.subtype : subtype
              }`
            )}
          </span>
          {hasPodcast && <PodcastLabel />}
        </div>

        {isSmall && descriptionElement}
      </div>
      <div className="flex items-center space-x-2 px-4 pb-8 sm:p-0">
        {!isSmall && descriptionElement}
        {cardImage ? (
          <div className="block h-24 w-32 shrink-0 overflow-hidden sm:h-32 sm:w-52">
            <PictureResource
              config={{
                url: cardImage,
                alt: alt || imgName,
                images,
              }}
              style={{
                objectPosition: isValid ? position : 'center',
                objectFit: 'cover',
                width: '100%',
                height: '100%',
              }}
              loading="eager"
            />
          </div>
        ) : null}
      </div>
    </article>
  );
}

export default ArticleCard;
