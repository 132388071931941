import classnames from 'classnames';
import { AlternatingTextColorElement } from 'components';
import { useCardSize, useTitleHeight } from 'hooks';
import { getAssetUrl } from 'utils';

const JOURNEY_CARD_HEIGHT = 384;

function JourneyCard({ journey, className, showDescription }) {
  const { name, description, key, thumbnail, id } = journey;
  const { imgHeight } = useCardSize();

  const titleId = id;
  const logos = false;

  const bodyStyle = {
    height: JOURNEY_CARD_HEIGHT - imgHeight,
  };

  const contentLines = useTitleHeight(titleId, bodyStyle, id, 'journey', logos);

  return (
    <article
      className={classnames(
        'flex cursor-pointer flex-col bg-white text-smd-main antialiased shadow-md',
        className
      )}
    >
      <img
        src={getAssetUrl(key ?? thumbnail)}
        alt={name}
        style={{ height: imgHeight }}
        className="block object-cover"
      />
      <div
        className={
          'relative flex h-full flex-col space-y-2 p-smd-sm pt-smd-xs lg:p-smd-base lg:pt-smd-lg'
        }
      >
        <div
          className="line-clamp-5 flex space-x-1 text-2xl font-bolder tracking-widest"
          id={titleId}
        >
          <i className="storymd-plus shrink-0" />
          <AlternatingTextColorElement
            name={name}
            evenColor="text-smd-gray-dark"
            oddColor="text-smd-accent-bright"
          />
        </div>
        {showDescription && (
          <p
            className={classnames('break-words text-smd-base', contentLines)}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </div>
    </article>
  );
}

export default JourneyCard;
