import { useEffect, useRef, useState } from 'react';
import { cn } from 'lib/utils';
import { useTranslation } from 'react-i18next';
import { minutesInHour, secondsInHour, secondsInMinute } from 'date-fns';
import InputMask from 'react-input-mask';

import FormController from 'components/forms/Form/FormController';
import { useManualInputDefaultProps } from '../hooks';

const REQUIRED_ERROR = 'required';

export function MaskInput({ mask, ...props }) {
  const newProps = useManualInputDefaultProps(props);
  const ref = useRef(null);

  useEffect(() => {
    const customError = newProps?.error?.type;
    const input = ref?.current?.getInputDOMNode();
    if (customError && customError !== REQUIRED_ERROR && input) {
      input.focus();
    }
  }, [newProps?.error?.type]);

  return (
    <InputMask
      mask={mask}
      className={cn(
        'smd-input-focus-primary !focus:outline-none',
        props.className
      )}
      ref={ref}
      {...newProps}
    />
  );
}

export function ControlledMaskInput({
  name,
  mask,
  control,
  rules = {},
  ...rest
}) {
  const { t } = useTranslation();
  const watchValue = rest.watch(`${rest.prefix}.value`) || '';
  const [hours, minutes, seconds] = watchValue.split(' : ');

  const removeNonNumericSymbols = (value) => {
    return value?.replace(/\D/g, '0');
  };

  const [totalSeconds, setTotalSeconds] = useState(0);

  const timeValidationRules = {
    validate: {
      max: () => {
        return minutes >= minutesInHour || seconds >= secondsInMinute
          ? t('errors.invalid-duration')
          : true;
      },
    },
    ...rules,
  };

  useEffect(() => {
    const hoursToCalculate = Number(removeNonNumericSymbols(hours));
    const minutesToCalculate = Number(removeNonNumericSymbols(minutes));
    const secondsToCalculate = Number(removeNonNumericSymbols(seconds));

    setTotalSeconds(
      Number(hoursToCalculate) * secondsInHour +
        Number(minutesToCalculate) * secondsInMinute +
        Number(secondsToCalculate)
    );
    rest.setValue(`${rest.prefix}.totalSeconds`, totalSeconds);
  }, [hours, minutes, rest, seconds, totalSeconds]);

  return (
    <FormController
      name={name}
      control={control}
      rules={timeValidationRules}
      render={({ field }) => <MaskInput mask={mask} {...rest} {...field} />}
    />
  );
}
