import PropTypes from 'prop-types';
import { cn } from 'lib/utils';
import styles from './Slider.module.css';

function Slider(props) {
  const {
    min,
    max,
    step,
    onChange,
    value,
    className = 'bg-smd-accent text-smd-accent',
    sliderClassName = '',
  } = props;

  return (
    <div className={cn('relative flex h-2 w-full flex-col', className)}>
      <input
        type="range"
        min={min}
        max={max}
        step={(step ?? 1) / 100}
        value={value}
        onChange={({ target: { value } }) => onChange?.(parseFloat(value))}
        className={`${styles.slider} ${sliderClassName}`}
      />
    </div>
  );
}

Slider.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number,
  onChange: PropTypes.func,
  step: PropTypes.number,
  className: PropTypes.string,
  sliderClassName: PropTypes.string,
};

export default Slider;
