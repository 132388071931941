import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { Aspect, CTA, Video } from 'components';
import { Button } from 'components/forms';
import { ChevronRight, ExternalLink } from 'components/icons';

import { getAssetUrl } from 'utils';
import { useMedia } from 'hooks';
import { useAuth } from 'Auth';
import { cn } from 'lib/utils';
import { PHR_ROUTES } from 'my-phr/const';
import keycloak from 'keycloak';

function RedeemAccessCodeBlock() {
  const { t } = useTranslation();
  const redirectUri = keycloak.createRegisterUrl({
    redirectUri: `${window.location.origin}${PHR_ROUTES.SETUP_REDEEM}`,
  });
  return (
    <div className="w-full space-x-1 text-center md:text-left">
      <span>{t('my-phr.setup.have-access-code')}</span>
      <Button
        onClick={() => (window.location = redirectUri)}
        size="none"
        color="anchor"
        className="font-bold"
      >
        {t('my-phr.setup.redeem-access-code')}
      </Button>
    </div>
  );
}

function HeroContentItem({ content, ...props }) {
  const { t } = useTranslation();
  const { authenticated } = useAuth();
  const {
    logoUrl,
    name,
    title,
    text,
    ctaProps,
    ctaText,
    type,
    pricingButton,
    hasRedeemCodeButton, // main website
    healthSignupProps, // distributions only
    loginRedirect,
    thumbnail,
    key,
    imageTitle,
    credit,
    alt,
  } = content;

  const { onClick, onContactUsClick, onGetAccessCodeClick } = props;
  const isLarge = useMedia(useMedia.LARGE);

  const allowAutoPlay = isLarge && content?.['auto-play'];

  const hasContactUsLink = Boolean(healthSignupProps?.contactUs?.enabled);

  const hasStartTrialButton = Boolean(
    healthSignupProps?.startTrialButton?.enabled
  );

  return (
    <div className="relative grid min-h-[80vh] overflow-hidden md:content-center">
      <div className="flex flex-wrap items-center md:flex-nowrap md:space-x-8">
        <div className="w-full py-0 pb-4 pt-10 md:max-w-1/2 md:py-10">
          {logoUrl && (
            <img
              src={logoUrl}
              alt={alt ?? name}
              className="mx-auto h-full w-32 pb-2 md:mx-0"
            />
          )}
          <h1
            className={classnames(
              'text-smd-h3 font-semibold md:text-[30px]',
              'mx-auto md:mx-0',
              'text-center md:text-left',
              'max-w-130 md:max-w-none'
            )}
          >
            {title}
          </h1>
          <div
            className={classnames(
              'custom-bullet',
              'text-smd-base',
              'mx-auto py-4 md:mx-0 md:py-8',
              'max-w-130 md:max-w-none'
            )}
            dangerouslySetInnerHTML={{ __html: text }}
          />

          {hasStartTrialButton && (
            <div
              className="w-full py-2"
              dangerouslySetInnerHTML={{
                __html: healthSignupProps.startTrialButton.heading,
              }}
            />
          )}

          <div className="flex flex-col items-center gap-4 text-center md:items-start md:text-left lg:flex-row">
            <div
              className={cn(
                'my-4 flex flex-col items-center gap-2 md:flex-row',
                authenticated ? 'lg:py-2' : ''
              )}
            >
              <CTA color="primary" ctaProps={ctaProps} className="font-bold">
                {ctaText}
                <ChevronRight className="ml-1 h-5 w-5" strokeWidth="2" />
              </CTA>

              {hasStartTrialButton && (
                <CTA
                  redeemsAccessCode
                  color="primary"
                  ctaProps={{ ctaAction: 'register' }}
                  className="flex space-x-2"
                  hasTrialButton
                  signUpDomainUrl={
                    healthSignupProps.startTrialButton.signUpDomainUrl
                  }
                >
                  <ExternalLink className="h-4 w-4" />
                  <span>{healthSignupProps.startTrialButton.buttonLabel}</span>
                </CTA>
              )}
              {pricingButton && (
                <Button.Tertiary onClick={onClick}>
                  {pricingButton}
                </Button.Tertiary>
              )}
            </div>
          </div>

          {hasRedeemCodeButton && !hasStartTrialButton && (
            <RedeemAccessCodeBlock />
          )}

          {Boolean(healthSignupProps?.accessCode?.enabled) && (
            <div className="my-4 flex flex-col space-y-4 text-center md:text-left">
              <div>{healthSignupProps.accessCode.label}</div>
              <div>
                <span>{healthSignupProps.accessCode.requestLabel}</span>
                <Button.Primary
                  className="px-1 font-bold"
                  color="anchor"
                  size="anchor"
                  onClick={() => onGetAccessCodeClick()}
                >
                  {healthSignupProps.accessCode.requestButtonLabel}
                </Button.Primary>
              </div>
            </div>
          )}

          {!hasStartTrialButton && loginRedirect && !authenticated && (
            <div
              className="pt-4 text-center md:text-left"
              dangerouslySetInnerHTML={{ __html: loginRedirect }}
            />
          )}

          {hasContactUsLink && (
            <div>
              <span>{healthSignupProps.contactUs.label}</span>
              <Button
                color="anchor"
                size="anchor"
                onClick={onContactUsClick}
                className="px-1 font-bold"
              >
                {healthSignupProps.contactUs.buttonLabel}
              </Button>
            </div>
          )}
        </div>
        <div className="w-full pb-2 md:max-w-1/2 md:pb-0">
          {type === 'video' ? (
            <Aspect.Fixed ratio={16 / 9} className="overflow-hidden">
              <Video
                {...{
                  thumbnail: getAssetUrl(thumbnail),
                  url: getAssetUrl(key),
                  controls: true,
                  className: 'border',
                  width: '100%',
                  height: '100%',
                  autoPlay: allowAutoPlay,
                  autoPlayMuted: allowAutoPlay,
                  loop: true,
                }}
              />
            </Aspect.Fixed>
          ) : (
            <img
              src={getAssetUrl(key)}
              alt={alt ?? name}
              className="h-full max-h-80 w-full select-none object-contain md:max-h-full"
            />
          )}
        </div>
      </div>
      {type === 'image' && (
        <div className="absolute bottom-0 right-0 hidden w-full bg-white py-1 text-right text-smd-gray-dark md:block">
          <p className="text-smd-sm">{imageTitle}</p>
          <p className="text-smd-xs italic">
            {t('journals.asset.source.label-image')} {credit}
          </p>
        </div>
      )}
    </div>
  );
}

export default HeroContentItem;
