import {
  createContext,
  Dispatch,
  ReactNode,
  RefObject,
  SetStateAction,
  useContext,
  useRef,
  useState,
} from 'react';
import { lightTheme, Theme } from './themes';

type PodcastContextType = {
  duration: number;
  setDuration: Dispatch<SetStateAction<number>>;

  progress: number;
  setProgress: Dispatch<SetStateAction<number>>;

  isPlaying: boolean;
  setIsPlaying: Dispatch<SetStateAction<boolean>>;

  audioReactRef: RefObject<HTMLAudioElement>;

  muted: boolean;
  setMuted: Dispatch<SetStateAction<boolean>>;

  expanded: boolean;
  setExpanded: Dispatch<SetStateAction<boolean>>;

  theme: Theme;
  setTheme: Dispatch<SetStateAction<Theme>>;
};

const AudioPlayerContext = createContext<PodcastContextType | undefined>(
  undefined
);

type AudioPlayerProviderProps = {
  children: ReactNode;
};

export const AudioPlayerProvider = ({ children }: AudioPlayerProviderProps) => {
  const [duration, setDuration] = useState<number>(0);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [muted, setMuted] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [expanded, setExpanded] = useState<boolean>(true);
  const [theme, setTheme] = useState<Theme>(lightTheme);

  const audioReactRef = useRef();

  const value = {
    duration,
    setDuration,
    isPlaying,
    setIsPlaying,
    setProgress,
    progress,
    audioReactRef,
    muted,
    setMuted,
    expanded,
    setExpanded,
    theme,
    setTheme,
  };

  return (
    <AudioPlayerContext.Provider value={value}>
      {children}
    </AudioPlayerContext.Provider>
  );
};

export const useAudioPlayerContext = (): PodcastContextType => {
  const context = useContext(AudioPlayerContext);

  if (context === undefined) {
    throw new Error(
      'useAudioPlayerContext must be used within an AudioPlayerProvider'
    );
  }

  return context;
};
