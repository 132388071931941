import { secondsToHours, secondsToMinutes } from 'date-fns';

export function formatTime(time: number | undefined): string {
  if (typeof time === 'number' && !isNaN(time)) {
    const hours = secondsToHours(time);
    const minutes = secondsToMinutes(time);
    const seconds = Math.floor(time % 60);
    const formatHours = hours.toString().padStart(2, '0');
    const formatMinutes = minutes.toString().padStart(2, '0');
    const formatSeconds = seconds.toString().padStart(2, '0');
    return hours >= 1
      ? `${formatHours}:${formatMinutes}:${formatSeconds}`
      : `${formatMinutes}:${formatSeconds}`;
  }
  return '00:00';
}

export function toLightboxPodcast(podcast) {
  return {
    id: podcast?.id,
    credit: podcast?.credit,
    description: podcast?.description,
    url: podcast?.url,
    thumbnail: podcast?.thumbnail,
    name: podcast?.name,
    type: podcast?.type,
  };
}
