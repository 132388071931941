import withCdnResources from 'library/withCdnResources';
import { ProgressBar, Controls } from '../components';
import { AudioPlayerProvider } from '../PodcastContext';
import AudioElement from '../AudioElement';
import { lightTheme } from '../themes';

function AssetPodcastPlayer(props) {
  const { url, thumbnail, alt } = props;
  return (
    <div className="mx-auto flex max-w-96 flex-col gap-y-4 bg-white p-4">
      <img
        src={thumbnail}
        alt={alt}
        className="h-80 w-80 rounded-lg object-cover"
      />
      <ProgressBar />
      <Controls />
      <AudioElement theme={lightTheme} src={url} />
    </div>
  );
}

function AssetPagePodcast(props) {
  return (
    <AudioPlayerProvider>
      <AssetPodcastPlayer {...props?.config} />
    </AudioPlayerProvider>
  );
}

export default withCdnResources(['config.thumbnail'], AssetPagePodcast);
