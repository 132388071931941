import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useMedia } from 'hooks';
import { Roles, useAuth } from 'Auth';

import useEnabledFeatures from '../useEnabledFeatures';
import BackToMainButton from '../BackToMainButton';
import { MenuNavLink, menuItemActiveStyles } from '../MenuNavLink';
import { HoverMenu } from '../HoverMenu';

function MainMenuItems({ dispatch, hasBackButton, admin }) {
  const { t } = useTranslation();

  const isLarge = useMedia(useMedia.LARGE);
  const location = useLocation();

  const { isCommunitiesEnabled } = useEnabledFeatures();

  const { hasRole } = useAuth();
  const isHealthProfessional = hasRole(Roles.HP);

  const journeyClassName = location.pathname.includes('/journeys')
    ? menuItemActiveStyles
    : '';

  function openJourney() {
    isLarge && dispatch({ type: 'open', menu: 'isJourneysOpen' });
  }
  return (
    <>
      {hasBackButton && <BackToMainButton className="md:hidden" />}
      <HoverMenu.Journeys
        journeyClassName={journeyClassName}
        onOpen={openJourney}
        onClose={() =>
          dispatch({ type: 'state', menu: { isJourneysOpen: false } })
        }
      />
      {isCommunitiesEnabled && (
        <MenuNavLink
          to="/communities"
          aria-label={t('labels.menu.communities')}
          data-testid="menuCommunitiesLink"
        >
          {t('main-menu.communities')}
        </MenuNavLink>
      )}
      {isHealthProfessional && (
        <MenuNavLink
          to="/poc"
          aria-label={t('labels.menu.stories')}
          data-testid="storiesLink"
        >
          {t('main-menu.poc')}
        </MenuNavLink>
      )}

      <MenuNavLink
        to="/stories"
        aria-label={t('labels.menu.poc')}
        data-testid="menuPocLink"
      >
        {t('main-menu.stories')}
      </MenuNavLink>

      {admin && (
        <MenuNavLink
          to="/admin"
          aria-label={t('labels.menu.admin')}
          data-testid="menuAdminLink"
        >
          {t('main-menu.admin')}
        </MenuNavLink>
      )}
    </>
  );
}

export default MainMenuItems;
