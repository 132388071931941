import { Dispatch, SetStateAction } from 'react';
import { ChevronDown, ChevronUp } from 'components/icons';
import { Button } from 'components/forms';

interface ToggleButtonProps {
  isOpen: boolean;
  onToggle: Dispatch<SetStateAction<boolean>>;
}

export default function ToggleButton({ isOpen, onToggle }: ToggleButtonProps) {
  const Icon = isOpen ? ChevronDown : ChevronUp;
  return (
    <Button
      onClick={() => onToggle((value) => !value)}
      size="none"
      color="ghost"
      className="!absolute -top-4 right-5 h-7 w-7 rounded-full border bg-white p-1 shadow-md md:right-0"
    >
      <Icon className="h-full w-full stroke-4" />
    </Button>
  );
}
