import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player/lazy';
import classnames from 'classnames';

import { Expand, Pause, Play, VolumeMute, VolumeUp } from 'components/icons';
import { Slider } from 'components';
import screenfull from 'screenfull';
import { useMedia } from 'hooks';

function getFormattedDuration(timeInSeconds) {
  return `${Math.floor(timeInSeconds / 60)}:${Math.ceil(timeInSeconds % 60)
    .toString(10)
    .padStart(2, '0')}`;
}
function getProgress(value, duration) {
  return (value * duration) / 100;
}
const Video = forwardRef(
  (
    {
      thumbnail,
      autoPlayMuted,
      playPause,
      autoPlay = false,
      embedded = false,
      wrapperClassName,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation();
    const isLarge = useMedia(useMedia.LARGE);
    const thumbProps = thumbnail ? { light: thumbnail } : {};
    const [state, setState] = useState({
      progress: 0,
      volume: 0.5,
      muted: !isLarge || Boolean(autoPlayMuted),
    });
    const { duration, progress, muted, seeking, volume, playing, idleCounter } =
      state;
    const currentRef = useRef(ref);
    const wrapperRef = useRef();
    const showVolume = useMedia(useMedia.MEDIUM);
    const isTouch = useMedia(useMedia.POINTER_COARSE);
    const controlDisplayClass = `w-full h-12 bg-black bg-opacity-50 absolute bottom-0 prevent-swiper ${
      idleCounter > 3 ? 'hidden' : 'animate-fade-in-up '
    }`;

    const showCustomControls = !isTouch || !embedded;

    const handlePlayPause = useCallback((playing) => {
      displayControls();
      setState((state) => ({ ...state, playing }));
    }, []);

    useEffect(() => {
      handlePlayPause(playPause?.playing);
    }, [playPause, handlePlayPause]);

    //In order to allow autoplay, the preview needs to be disabled,
    //so that playPause prop can trigger handlePlayPause callback
    useEffect(() => {
      if (autoPlay && currentRef.current) {
        currentRef.current.state.showPreview = !autoPlay;
        handlePlayPause(true);
      }
    }, [autoPlay, handlePlayPause]);

    let incrementIdle = function () {
      setState((state) => ({
        ...state,
        idleCounter: idleCounter + 1,
      }));
    };

    function handleProgress(progress = 0) {
      incrementIdle();
      setState((state) => ({
        ...state,
        progress,
      }));
    }

    function handleDuration(duration) {
      setState((state) => ({ ...state, duration }));
    }

    function handleMute() {
      if (muted && volume === 0) {
        setState((state) => ({ ...state, volume: 0.5 }));
      }
      setState((state) => ({ ...state, muted: !muted }));
    }

    function handleProgressChange(value) {
      handleProgress(getProgress(value, duration));
    }

    function endSeeking() {
      setState((state) => ({ ...state, seeking: false }));
      currentRef.current.seekTo(progress);
    }

    function handleExpand() {
      screenfull.toggle(wrapperRef.current);
    }

    function startSeeking() {
      setState((state) => ({ ...state, seeking: true }));
    }

    function handleVolume(volume) {
      if (volume === 0) setState((state) => ({ ...state, muted: true }));
      if (muted && volume > 0)
        setState((state) => ({ ...state, muted: false }));
      setState((state) => ({ ...state, volume }));
    }

    function displayControls() {
      setState((state) => ({ ...state, idleCounter: 0 }));
    }

    return (
      <div
        onMouseMove={displayControls}
        ref={wrapperRef}
        className={classnames('h-full w-full', wrapperClassName)}
      >
        <div
          className="h-full w-full overflow-hidden"
          onClick={() => handlePlayPause(!playing)}
          onKeyDown={(e) => {
            if (e.key === ' ') {
              return handlePlayPause(!playing);
            }
            if (e.key === 'Enter') {
              return e.preventDefault();
            }
          }}
        >
          <ReactPlayer
            previewTabIndex={-1}
            ref={currentRef}
            config={{
              youtube: {
                playerVars: {
                  autoplay: 1,
                },
              },
              file: {
                attributes: {
                  onContextMenu: (e) => e.preventDefault(),
                  disablePictureInPicture: true,
                  controlsList: 'nodownload',
                },
              },
            }}
            playIcon={
              <button
                className="flex-center smd-focus-visible-secondary h-16 w-16 cursor-pointer rounded-full bg-smd-accent"
                aria-label={t('common.play')}
              >
                <Play className="relative -right-0.5 h-4 w-4 text-white" />
              </button>
            }
            {...thumbProps}
            {...props}
            controls={isTouch && embedded}
            onProgress={({ playedSeconds }) => {
              if (!seeking) handleProgress(playedSeconds);
            }}
            onDuration={handleDuration}
            playsinline
            playing={playing}
            muted={muted}
            volume={volume}
            onPlay={() => handlePlayPause(true)}
            onPause={() => handlePlayPause(false)}
            onEnded={() => handlePlayPause(false)}
          />
        </div>
        {showCustomControls && duration && (
          <div className={controlDisplayClass}>
            <div
              onMouseDown={startSeeking}
              onTouchStart={startSeeking}
              onMouseUp={endSeeking}
              onTouchEnd={endSeeking}
            >
              <Slider
                min={0}
                max={100}
                value={(progress / duration) * 100}
                onChange={handleProgressChange}
                step={100 / duration}
              />
            </div>

            <div
              className={`absolute mt-3 flex w-full select-none justify-between pl-smd-lg text-white`}
            >
              <div className="flex justify-center space-x-smd-xl align-middle ">
                <button
                  className="cursor-pointer"
                  onClick={() => handlePlayPause(!playing)}
                  onKeyDown={() => handlePlayPause(!playing)}
                >
                  {playing ? (
                    <Pause className="h-4 w-4" />
                  ) : (
                    <Play className="h-4 w-4" />
                  )}
                </button>
                <div className="cursor-pointer" onClick={handleMute}>
                  {muted ? (
                    <VolumeMute className="h-4.5 w-4.5 " />
                  ) : (
                    <VolumeUp className="h-4.5 w-4.5" />
                  )}
                </div>
                {showVolume && (
                  <div className="mt-1">
                    <Slider
                      min={0}
                      max={1}
                      value={volume}
                      onChange={handleVolume}
                      step={0.01}
                    />
                  </div>
                )}
                <div className="leading-4">{`${getFormattedDuration(
                  progress
                )} / ${getFormattedDuration(duration)}`}</div>
              </div>
              {screenfull && screenfull?.isEnabled && (
                <div
                  className="cursor-pointer pr-smd-lg"
                  onClick={handleExpand}
                >
                  <Expand className="h-4 w-4" />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
);
export default Video;
