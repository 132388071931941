import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isNil } from 'ramda';
import { Footer } from 'components';
import { Share } from 'components/icons';
import { ShareModal, SharePreview } from 'components/Share';
import { ViewerLayout, ViewerSidebar, ViewerTopbar } from 'components/viewer';
import LibraryAsset from 'journals/blocks/output/HtmlBlock/LibraryAsset';
import { useAssetLastLocation } from 'library/hooks';

function LibraryAssetPageViewer({ asset }) {
  const { t } = useTranslation();

  const { popAssetLastLocation } = useAssetLastLocation({
    onEvent: ({ method, lastLocation }) => {
      if (method === 'pop') {
        history.push(lastLocation);
      }
    },
  });

  const history = useHistory();
  const [share, setShare] = useState(false);
  if (isNil(asset)) return null;

  return (
    <>
      <ViewerLayout
        topbar={
          <ViewerTopbar
            title={asset?.name}
            logos={[ViewerTopbar.smdLogo]}
            onCloseClick={() => popAssetLastLocation()}
            onShareClick={() => setShare(true)}
          />
        }
        sidebar={
          <ViewerSidebar>
            <ViewerSidebar.Button onClick={() => setShare(true)}>
              <div className="flex flex-col items-center text-black">
                <Share className="w-5" />
                <span className="pt-0.5">{t('common.share')}</span>
              </div>
            </ViewerSidebar.Button>
          </ViewerSidebar>
        }
        main={
          <div className="flex h-full flex-col overflow-auto">
            <div className="grow p-4 lg:px-8">
              <div className="relative mx-auto max-w-4xl space-y-4">
                <LibraryAsset config={asset} />
                <div
                  className="asset-description mx-smd-base text-smd-sm leading-relaxed"
                  dangerouslySetInnerHTML={{ __html: asset.description }}
                />
              </div>
            </div>
            <Footer />
          </div>
        }
      />
      <ShareModal
        isOpen={share}
        onClose={() => setShare(false)}
        shareUrl={window.location.href}
      >
        <SharePreview {...asset} description={null} />
      </ShareModal>
    </>
  );
}

export default LibraryAssetPageViewer;
