import { useEffect } from 'react';
import withCdnResources from 'library/withCdnResources';
import { useAudioPlayerContext } from './PodcastContext';

function AudioElement({ src, theme }) {
  const {
    audioReactRef,
    setDuration,
    muted,
    isPlaying,
    setProgress,
    setIsPlaying,
    setTheme,
  } = useAudioPlayerContext();

  useEffect(() => {
    setTheme(theme);
  }, [setTheme, theme]);

  useEffect(() => {
    if (isPlaying) {
      audioReactRef.current?.play();
    } else {
      audioReactRef.current?.pause();
    }
  }, [isPlaying, audioReactRef]);

  function onLoadedMetadata() {
    if (audioReactRef?.current?.duration) {
      setDuration(audioReactRef?.current?.duration);
    }
  }

  function onTimeUpdate() {
    if (audioReactRef?.current?.currentTime) {
      setProgress(audioReactRef?.current?.currentTime);
    }
  }

  function onEnded() {
    setIsPlaying(false);
    setProgress(0);
  }

  function onExternalPause() {
    if (isPlaying) setIsPlaying(false);
  }

  function onExternalPlay() {
    if (!isPlaying) setIsPlaying(true);
  }
  return (
    <audio
      ref={audioReactRef}
      muted={muted}
      src={src}
      className="hidden"
      onLoadedMetadata={onLoadedMetadata}
      onEnded={onEnded}
      onTimeUpdate={onTimeUpdate}
      onPause={onExternalPause}
      onPlay={onExternalPlay}
    />
  );
}

export default withCdnResources(['src'], AudioElement);
