import { PHR_DATA_TYPE } from '../../../const';

const MANUAL_INPUTS_ORDER = [
  'NOTE',
  'ALLERGY',
  'CONDITION',
  'MEASUREMENT',
  'MEDICATION',
  'PROCEDURE',
  'OBSERVATION',
  'OBSERVATION_PANEL',
  'VACCINE',
];

export const MANUAL_INPUT_CONFIG_TYPE = Object.freeze({
  ALLERGY_INTOLERANCE: 'allergyintolerance',
  CONDITION: 'condition',
  MEDICATION: 'medication',
  OBSERVATION: 'observation',
  OBSERVATION_PANEL: 'lab_panel',
  PROCEDURE: 'procedure',
  VACCINE: 'immunization',
  NOTE: 'note',
});

export const MANUAL_INPUT_TYPE = Object.freeze({
  ATTACHMENTS: 'FILE',
  TEXT: 'TEXT',
  TEXTAREA: 'TEXTAREA',
  RICH_TEXTAREA: 'RICH_TEXTAREA',
  SEARCH: 'SEARCH',
  SELECT: 'SELECT',
  SELECT_SUGGESTIONS: 'SELECT_SUGGESTIONS',
  MULTISELECT: 'MULTISELECT',
  LIBRARY: 'LIBRARY',
  PANEL: 'PANEL',
  DATE: 'DATE',
  TIME: 'TIME',
  DATE_TIME: 'DATETIME',
  DATE_RANGE: 'DATERANGE',
  REACTIONS: 'REACTIONS',
  NUMBER: 'NUMBER',
  RATIO: 'RATIO',
  RESULT: 'RESULT',
  HIDDEN: 'HIDDEN',
  UNIT_SUGGESTIONS: 'UNIT_SUGGESTION', // TODO: Temporary
});

export const RENDER_TYPES = Object.freeze({
  CHIP: 'chip',
  DROPDOWN: 'dropdown',
  ICON: 'icon',
});

export const AUTOCOMPLETE_TYPES = Object.freeze({
  ALLERGY: 'allergyintolerance',
  OBSERVATION: 'observation',
  MEDICATION: 'medication',
  CONDITION: 'condition',
  PROCEDURE: 'procedure',
  VACCINE: 'immunization',
});

export const DEFAULT_STORYBLOCK_TYPES = Object.freeze({
  ALLERGY: 'allergyintolerance',
  OBSERVATION: 'observation',
  OBSERVATION_PANEL: 'lab_panel',
  MEDICATION: 'medication',
  CONDITION: 'condition',
  PROCEDURE: 'procedure',
  VACCINE: 'immunization',
  MEASUREMENT: 'observation',
  NOTE: 'note',
});

export const AUTOCOMPLETE_CATEGORIES = Object.freeze({
  MEASUREMENT: 'measurement',
  LAB_TEST: 'lab_test',
  LAB_PANEL: 'lab_panel',
  ACTIVITY: 'activity',
});

export const MANUAL_INPUT_CATEGORY = Object.freeze({
  ...PHR_DATA_TYPE,
  NOTE: 'NOTE',
});

export const MANUAL_INPUT_CATEGORIES = MANUAL_INPUTS_ORDER.map(
  (x) => MANUAL_INPUT_CATEGORY[x]
);

export const DEFAULT_TRANSLATION_SUFFIX = 'my-phr';

// TODO - to be fixed long term in the future
export const LAB_PANEL_LABEL = 'Lab panel';
export const LAB_TEST_LABEL = 'Test';
export const VACCINE_LABEL = 'Vaccine';
export const VACCINATION_LABEL = 'Vaccination';
