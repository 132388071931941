import { forwardRef } from 'react';
import { Form } from 'components/forms';
import { Clock } from 'components/icons';
import manualInputsRegistry from '../manualInputsRegistry';

import { useManualInputDefaultProps } from '../hooks';
import { MANUAL_INPUT_TYPE } from '../const';
import { ControlledMaskInput } from './MaskInput';

export function TimeInput(props) {
  const newProps = useManualInputDefaultProps(props);

  return (
    <div className="w-[256px]">
      <div className="relative">
        <Form.Input
          {...newProps}
          mask="99 : 99 : 99"
          as={MaskControlled}
          className="w-full"
        />
        <Clock className="pointer-events-none absolute right-3 top-12 h-5 w-5 -translate-y-2/4 transform text-smd-accent" />
      </div>
    </div>
  );
}

const MaskControlled = forwardRef((props, ref) => {
  return (
    <ControlledMaskInput
      {...props}
      mask={props.mask}
      placeholder={props.placeholder}
      ref={ref}
    />
  );
});

MaskControlled.displayName = 'MaskControlled';

manualInputsRegistry.register(MANUAL_INPUT_TYPE.TIME, TimeInput);
