import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { add } from 'date-fns';
import { Link } from 'react-router-dom';
import { equals, pipe, reduce, values } from 'ramda';

import { useLibraryAssetRoutedModal } from 'library/hooks';
import { useParseSearch, useSearch } from 'search/hooks';

import { InfiniteScrollTrigger } from 'components';
import { Button } from 'components/forms';
import { NoSearchResults, SkeletonCardGrid } from 'journals/components';

import { LibraryAssetListSkeleton } from 'library/components/asset/LibraryAssetList';
import { LibraryListSkeleton } from 'library/components/LibraryList';

import {
  LibraryAssetModule,
  LibraryStoryModule,
  LibraryJournalModule,
  LibraryTopicModule,
  LibraryArticleModule,
  LibraryPodcastModule,
} from './modules';

function LibraryResourcesResults({
  queryParams,
  loading,
  parsedSummaryResults,
}) {
  const { t } = useTranslation();

  const {
    searchResults,
    fetchNextPage,
    hasNextPage,
    isFetched,
    isFetching,
    isFetchingNextPage,
    glossary,
    isFetchingGlossary,
  } = useSearch(queryParams);
  const parsedSearchResults = useParseSearch(searchResults, queryParams);

  const glossaryData = glossary?.data?.length ? glossary.data[0] : {};

  const { openLibraryAssetModal } = useLibraryAssetRoutedModal({
    assets: parsedSearchResults,
    fetchMoreAssets: fetchNextPage,
    haseMoreAssets: hasNextPage,
    isFetching: isFetching,
  });

  const hasNoResults = useMemo(
    () =>
      !loading && pipe(values, reduce(add, 0), equals(0))(parsedSummaryResults),
    [parsedSummaryResults, loading]
  );

  const hasNoResourceTypeResults = useMemo(() => {
    if (isFetched && Boolean(searchResults)) {
      if (searchResults.pages[0]?.data.length > 0) return false;
      let isEmpty = false;
      for (const page of searchResults.pages) {
        if (Boolean(page.data.filter(Boolean).length) === false) {
          isEmpty = true;
        }
      }
      return isEmpty;
    }
  }, [isFetched, searchResults]);

  const showNoResultsScreen = hasNoResults || hasNoResourceTypeResults;
  const type = queryParams.type;

  const isLoading =
    loading || (isFetching && !isFetched && !isFetchingNextPage);

  const isJournal = type === 'healthtopic' || type === 'poc';
  const isAsset = type === 'image' || type === 'video';
  const isPodcast = type === 'audio';
  const isTopic = type === 'drug' || type === 'biomarker';
  const isEvent = type === 'event';
  const isArticle = type === 'all';

  const existingResults = parsedSummaryResults?.filter(
    (entry) => entry?.count > 0
  );

  const hasResultsElsewhere = existingResults.some((entry) =>
    Boolean(entry?.count)
  );

  return (
    <>
      {isLoading && isAsset && <LibraryAssetListSkeleton count={8} isLarge />}
      {isLoading && (isTopic || isEvent) && <LibraryListSkeleton.Wide />}
      {isLoading && isJournal && (
        <SkeletonCardGrid
          containerClassName=""
          className=""
          gridClassName=""
          title=""
        />
      )}

      {isAsset ? (
        <LibraryAssetModule
          key={type}
          assets={parsedSearchResults}
          onAssetClick={openLibraryAssetModal}
        />
      ) : isJournal ? (
        <LibraryJournalModule key={type} journals={parsedSearchResults} />
      ) : isEvent ? (
        <LibraryStoryModule stories={parsedSearchResults} />
      ) : isPodcast ? (
        <LibraryPodcastModule podcasts={parsedSearchResults} />
      ) : isArticle ? (
        <LibraryArticleModule
          articles={parsedSearchResults}
          glossary={glossaryData}
          isLoadingGlossary={isFetchingGlossary}
          isLoadingArticles={isLoading}
        />
      ) : (
        <LibraryTopicModule key={type} topics={parsedSearchResults} />
      )}

      <div className="py-8 text-center">
        {hasNextPage &&
          (!isArticle ? (
            <InfiniteScrollTrigger
              onFetch={fetchNextPage}
              isFetching={isFetchingNextPage}
            />
          ) : (
            <Button.Tertiary
              onClick={fetchNextPage}
              loading={isFetchingNextPage}
              className="font-semibold"
            >
              <span>{t('common.show-more-results')}</span>
            </Button.Tertiary>
          ))}
        {showNoResultsScreen && (
          <NoSearchResults
            title={t('library.search.no-results.missing-specific-entry', {
              entry: t(`library.search.${type}`, {
                count: 1,
              })?.toLocaleLowerCase?.(),
            })}
          >
            {hasResultsElsewhere ? (
              <>
                <h2 className="mb-2 font-semibold">
                  {t('library.search.no-results.other-suggestions')}:
                </h2>
                <ul className="list-disc px-5">
                  {existingResults?.map?.((entry) => (
                    <li key={entry?.key} className="py-1">
                      <Link
                        to={entry?.path}
                        className="text-smd-accent underline"
                      >
                        {t(`library.search.other-results.${entry?.key}`, {
                          count: entry?.count,
                          resultCount: entry?.count,
                        })}
                      </Link>
                    </li>
                  ))}
                </ul>
              </>
            ) : null}
          </NoSearchResults>
        )}
      </div>
    </>
  );
}

export default LibraryResourcesResults;
