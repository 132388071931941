export function getBackToTopButtonClasses({
  podcastExpanded,
}: {
  podcastExpanded: boolean;
}): string {
  return podcastExpanded ? 'bottom-[12.5rem] md:bottom-36' : 'bottom-16';
}

export function getFloatingActionsButtonClasses({
  hasPodcast,
  podcastExpanded,
  backToTopBtnVisible,
}: {
  hasPodcast: boolean;
  podcastExpanded: boolean;
  backToTopBtnVisible: boolean;
}): string {
  if (!hasPodcast) {
    return backToTopBtnVisible ? 'bottom-24' : 'bottom-8';
  }

  if (hasPodcast && !podcastExpanded) {
    return backToTopBtnVisible ? 'bottom-32' : 'bottom-16';
  }

  if (hasPodcast && podcastExpanded) {
    return backToTopBtnVisible ? 'bottom-64' : 'bottom-52';
  }
}
