import { ReactNode } from 'react';
import { cn } from 'lib/utils';

interface PlayerButtonProps {
  children: ReactNode;
  onClick: any;
  className?: string;
}

export default function PlayerButton({
  children,
  onClick,
  className,
}: PlayerButtonProps) {
  return (
    <button
      onClick={onClick}
      className={cn(
        'select-none rounded-full focus:outline-none focus-visible:outline-none',
        className
      )}
    >
      {children}
    </button>
  );
}
