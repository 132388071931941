import { useState } from 'react';
import PropTypes from 'prop-types';

import withCdnResources from 'library/withCdnResources';
import { extractWebpThumbnail, getFocalPosition } from 'utils';

import { mergeAll } from 'ramda';
import { PictureResource } from './media';
import { BlurredLayer, hasSensitiveTags, blurredStyle } from './BlurredLayer';
import { Skeleton } from './index';

export function WideCardSkeleton() {
  return (
    <div className="flex w-full flex-col shadow-md sm:h-52 sm:flex-row">
      <Skeleton className="h-48 w-full shrink-0 sm:h-auto sm:w-72" />

      <div className="flex flex-grow flex-col overflow-hidden px-smd-md py-smd-sm pb-smd-base sm:py-smd-lg sm:pl-smd-xl sm:pr-smd-xxl">
        <Skeleton.Text className="mb-smd-xxs w-1/3 text-smd-xs" />
        <Skeleton.Text className="mb-smd-xs text-smd-h4" />
        <Skeleton.Text className="mb-smd-base hidden text-smd-sm sm:block" />
        <Skeleton.Text lines="3" trimLastLine className="text-smd-sm" />
      </div>
    </div>
  );
}

export function WideCard({
  asset,
  fallbackThumbnailUrl,
  duration,
  displayImage = Boolean(asset?.thumbnail || fallbackThumbnailUrl),
  title,
  synonyms,
  plainText,
}) {
  const { focalPoint, thumbnail, alt, title: imgName, images } = asset ?? {};
  const { position, isValid } = getFocalPosition(focalPoint);
  const webpThumbnail = extractWebpThumbnail(images);

  const isSensitive = hasSensitiveTags(asset);

  const [shouldDisplayImage, setShouldDisplayImage] = useState(
    () => displayImage
  );

  const pictureStyle = {
    objectPosition: isValid ? position : 'center',
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  };

  return (
    <article className="flex w-full flex-col bg-white text-smd-main antialiased shadow-md duration-100 hover:scale-1025 sm:h-52 sm:flex-row">
      {shouldDisplayImage && (
        <div className="block h-48 w-full shrink-0 overflow-hidden sm:h-auto sm:w-72">
          <BlurredLayer isSensitive={isSensitive} className="relative">
            {({ isBlurred }) => (
              <PictureResource
                config={{
                  url: webpThumbnail?.path || thumbnail || fallbackThumbnailUrl,
                  alt: alt || imgName,
                  images,
                }}
                style={
                  isBlurred
                    ? mergeAll([pictureStyle, blurredStyle])
                    : pictureStyle
                }
                loading="eager"
                onError={() => setShouldDisplayImage(false)}
              />
            )}
          </BlurredLayer>
        </div>
      )}
      <div className="flex flex-grow flex-col overflow-hidden px-smd-md py-smd-sm pb-smd-base sm:py-smd-lg sm:pl-smd-xl sm:pr-smd-xxl">
        <h2 className="truncate text-smd-h4 font-semibold">{title}</h2>
        {duration ?? null}
        {synonyms?.length > 0 && (
          <p
            className="mt-smd-xs hidden truncate text-smd-sm text-smd-gray-dark sm:block"
            data-testid="synonyms"
          >
            {synonyms.join(', ')}
          </p>
        )}
        <p
          className="mt-smd-xs line-clamp-3 text-smd-sm sm:mt-smd-base"
          data-testid="description"
          dangerouslySetInnerHTML={{ __html: plainText }}
        />
      </div>
    </article>
  );
}

WideCard.propTypes = {
  displayImage: PropTypes.bool,
  subtype: PropTypes.string,
  title: PropTypes.string.isRequired,
  synonyms: PropTypes.arrayOf(PropTypes.string),
  description: PropTypes.string.isRequired,
  asset: PropTypes.object,
  fallbackThumbnailUrl: PropTypes.string,
};
export default withCdnResources(['thumbnail'], WideCard);
